@import url('https://fonts.googleapis.com/css2?family=Gantari:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');




.row_footer {
    display: flex;
    margin: 0 auto;
    width: 1320px;
    justify-content: space-between;
}

.colum_primary_footer {
    width: 320px;
}


.textPrimary_tkg {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
}

.textSecondary_tkg {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 1rem;
}

.textSecondary_option {
    cursor: pointer;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 1.0rem;

}

footer {
    padding-top: 1.4rem;
    align-items: center;

    bottom: 0;
    background-color: #0c5dd8;

    width: 100%;
    height: 340px;
}

.container_bottom_footer {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    background: #1b579b;

}

.svg_footer_payment > svg{
    width: 230px;
}

.svg_footer_payment > svg{
    z-index: 999999;
}

footer>.container_bottom_footer>.bottom_footer {
    display: flex;
    padding-top: 0.9rem;
    font-family: "Open Sans", sans-serif;
    font-size: 1.2rem;
    align-items: center;
    justify-content: space-between;
    color: white;
    width: 1320px;
    margin: 0 auto;
    height: auto;
}


.bottom_footer>img {
    width: 85px;
    height: 85px;
    border-radius: 100px;
}




@media screen and (max-width:720px) {
    footer {
        padding-top: 1.4rem;
        flex-direction: column;
        align-items: center;
        height: 100%;
        position: static;
        bottom: 0;
        background: #1b579b;
        width: 100%;
    }


    .row_footer {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        align-items: center;
        height: 100%;
        width: 100%;
        justify-content: space-between;
    }

    .container_bottom_footer {
        width: 100%;
        height: auto;
        position: static;
        bottom: 0;
        background-color: #0c5dd8;
    }
    footer>.container_bottom_footer>.bottom_footer {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        padding-top: 0.9rem;
        font-family: "Open Sans", sans-serif;
        font-size: 1.2rem;
        align-items: center;
        color: white;
        width: 100%;
        margin: 0 auto;
        height: auto;
    }
    .colum_primary_footer {
        width: 100%;
        margin-bottom: 3rem;
        text-align: center;
    }
}