/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --global-primary-color: white;
  --global-header-primary: #1653bd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* FONTS */

@font-face {
  font-family: "OpenSans-Regular";
  src: url("./fonts//Open_Sans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "OpenSans-Medium";
  src: url("./fonts//Open_Sans/OpenSans-Medium.ttf");
}

@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("./fonts//Open_Sans/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url("./fonts//Open_Sans/OpenSans-Bold.ttf");
}


@font-face {
  font-family: "Poppins-Regular";
  src: url("./fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("./fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./fonts/Poppins/Poppins-Bold.ttf");
}


.box_header_image_account {
  margin-top: 2.3em;
  cursor: pointer;
  display:flex;
  position: relative;
  right: 15px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 64px;
  font-family: "Poppins-SemiBold";
  color: white;
}

.box_header_image_account > p{
  position: relative;
  top: 8px;
  font-size: 0.8rem;
  width: 120px;
}

/* END FONTS */


.table_mobile {
  display: none;
}

/* CONTAINER PAGE */


.container_page {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: center;

      -ms-flex-pack: center;

          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/* CARD CARROUSEL */
.container_card_carrousel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 1320px;
  scroll-behavior: smooth;
  overflow-x: scroll;
}

.container_card_carrousel::-webkit-scrollbar {
  display: none;
}

.flex_buttons_carrousel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 90px;
  margin-left: 40px;
}

.flex_buttons_carrousel>button {
  background: none;
  border: none;
}

.flex_buttons_carrousel>buttton>svg {
  width: 120px;
  height: 120px;
}


.container_animation_pages {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  background: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100vh;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container_animation_pages>div {
  width: 520px;
}

.container_animation_pages .filter>button {
  font-family: "OpenSans-SemiBold";
  text-transform: uppercase;
  border-radius: 5px;
  font-size: 0.9rem;
  border: none;
  color: white;
  background-color: #333333;
  height: 50px;
}

.container_select_filter_sidebar {
  position: relative;
  margin-left: 1.9rem;
}

.custom-select {
  position:relative;
  padding-left: 0.1rem;
  width: 260px;
  border-radius: 5px;
  font-family: "Poppins-Medium";
  font-size: 0.9rem;
  padding:0.6rem;
  border-radius: 5px;
  outline: none;
  border: 1px solid #858585;

}

.selected-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.selected-option img {
  margin-right: 10px;
  width: 25px;
  -o-object-fit: contain;
     object-fit: contain;
  height: 25px;
}

.selected-option > span{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 220px;
  color: black;
  left: 5px;
  position: relative;
  font-family: "OpenSans-Medium";
  overflow-x: hidden;
}

.option > p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 220px;
  color: black;
  height: 30px;
  top: 5px;
  left: -6px;
  position: relative;
  font-family: "OpenSans-Medium";
  overflow-x: hidden;
}

.options {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.row_frete > select{
  outline: none;
  width: 210px;
  padding-left: 0.5rem;
  font-size: 1.1rem;
  font-family: "OpenSans-Medium";
  border: 1px solid #858585;
  border-radius: 4px;
  height: 35px;
  margin-left: 3rem;
}

.row_frete{
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
}

.container_select {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  text-align: left;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  margin-left:0rem;

}

.option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px;
  cursor: pointer;
}

.option img {
  margin-right: 30px;
  width: 35px;
  -o-object-fit: contain;
     object-fit: contain;
  height: 35px;
  border-radius: 5px;
}

.option:hover {
  background-color: #f1f1f1;
}


.select_filter_sidebar{
  padding-left: 0.1rem;
  width: 240px;
  border-radius: 5px;
  font-family: "Poppins-Medium";
  font-size: 0.9rem;
  padding:0.6rem;
  border-radius: 5px;
  outline: none;
  border: 1px solid #858585;
}

.container_select_variation{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 0px;
  margin-bottom: 7.6rem;
  margin-left: 2.1rem;
}

.container_select_filter_sidebar{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 0px;
  margin-bottom: 6rem;
}

.container_select_variation > label{
  font-family: "Poppins-Regular";
  color: #212529;
  margin-bottom: 0.9rem;
}

.container_select_filter_sidebar > label{
  font-family: "Poppins-Medium";
  color: #373435;
  margin-bottom: 0.9rem;
}

.box_multi_range_slider > label{

  font-family: "Poppins-Medium";
  color: #373435;
  margin-bottom: 0.9rem;
  margin-left: 1.9rem;

}

.select_filter {
  width: 230px;
  font-family: "OpenSans-SemiBold";
  font-size: 0.9rem;
  border-radius: 5px;
  outline: none;
  height: 30px;
  border: 1px solid #858585;
}

.container_checkbox>div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1.0rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container_checkbox>div>img {
  position: absolute;
  right: 20px;
  width: 50px;
  height: 50px;
  -o-object-fit: contain;
     object-fit: contain;
}

.container_checkbox>div>input[type="checkbox"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 15px;
  cursor: pointer;
  border-radius: 4px;
  height: 15px;
  border: 1px solid #333333;
}

.container_checkbox>div>input[type="checkbox"]:checked {

  background-color: #333333;

}


.container_checkbox>div>label {
  height: 23px;
  font-size: 0.9rem;
  cursor: pointer;
  font-family: "OpenSans-SemiBold";
  margin-left: 0.9rem;
}

.row_models>img {
  margin-right: 1.2rem;
  width: 70px;
  border: 1px solid #333333;
  -o-object-fit: cover;
     object-fit: cover;
  height: 70px;
  border-radius: 100px;
}


.container_filter_options>p {
  margin-top: 1.3rem;
  font-size: 1.0rem;
  text-transform: uppercase;
  font-family: "OpenSans-SemiBold";
  color: black;
}


.container_row_filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 1080px;
}

.container_row_acessories {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.filter {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 2rem;
  width: 270px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  min-height: 20vh;
  height: 100%;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.32);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.32);
  background-color: white;
}


.container_page_acessories {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#myCarousel {
  width: 780px;
  padding-left: 40px;
  margin-top: 1.87rem;
  margin-left: 0rem;
}

.animation_btn_sale{
  position: absolute;
  width: 140px;

}

.btn_sale {
  position: relative;
  width: 220px;
  
  margin-top: 1.2rem;
  height: 43px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  font-family: "OpenSans-Medium";
  font-size: 1rem;
  color: white;
  background-color: #0c5dd7;
  border-radius: 5px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}



.container_frete>img {
  width: 50px;
  height: 50px;
}

.container_frete>p {
  font-family: "OpenSans-Medium";
  margin-top: 0.1rem;
  font-size: 0.9rem;
}

.container_frete {
  margin-top: 2rem;
  border-top: 1px solid black;
}



.btn_cart {
  width: 188px;
  padding: 1rem;
  margin-top: 3rem;
  height: 49px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  font-family: "OpenSans-Medium";
  font-size: 0.8rem;
  color: white;
  border-radius: 5px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: black;
}

.btn_cart>svg {
  position: relative;
  left: 5px;
}

.carousel-caption>p {
  margin-top: 2rem;
}

/* HEADER */

.header {
  background-color: #0c5dd8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 154px;


}

.header_items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 40px;
  width: 1320px;
  height: 154px;
  background-color: none;
  margin: 0 auto;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.detailsProduct {
  font-family: "OpenSans-Medium";
  font-size: 1.3rem;
  border-bottom: 1px solid #858585;
}

.header_items>.company_icon {
  width: 100px;
  border-radius: 100px;
  height: 100px;
}


.lottie_animation_button {
  position: absolute;
  width: 90px;
  top: -20px;
  -o-object-fit: contain;
     object-fit: contain;
  height: 50px;
}

.container_animation_page{
  width: 100%;
}

.animation_json {
  width: 120px;
  -o-object-fit: contain;
     object-fit: contain;
  height: 120px;
}

.background_animation {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 9999;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.128);
  height: 100vh;
}

input[type="text"] {
  outline: none;
  font-size: 0.9rem;
  font-family: "OpenSans-Medium";
  border: 1px solid #858585;
  border-radius: 5px;
  height: 35px;
}



.input_frete {
  outline: none;
  padding-left: 0.5rem;
  font-size: 1.1rem;
  font-family: "OpenSans-Medium";
  border: 1px solid #858585;
  border-radius: 4px;
  height: 40px;
}

.container_select {
  margin-top: 0rem;
  margin-bottom: 0rem;
  margin-left: 2rem;
}

.container_select > select{
  outline: none;
  padding-left: 0.5rem;
  font-size: 1.1rem;
  font-family: "OpenSans-Medium";
  border: 1px solid #858585;
  border-radius: 4px;
  height: 35px;
  
}



.container_select>p {
  font-family: "OpenSans-Medium";
  height: 19px;
}

.container_select>select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 100%;
  height: 42px;
  outline: none;
  font-family: "OpenSans-Medium";
  font-size: 0.9rem;
  padding-left: 0.9rem;
  border: 1px solid #858585;
  border-radius: 5px;
}


/* END HEADER */


/* HEADER SECONDARY */

.container_header_secondary {
  width: 100%;
  border-bottom: 2px solid #F6F6F6;
}

.box_header_secondary {
  width: 1320px;
  margin: 0 auto;
  padding-left: 40px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}


.box_header_secondary>.dropdown>button {
  background-color: #333333;
  width: 230px;
  border: none;
  font-family: "OpenSans-SemiBold";
  color: white;
  padding: 0.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.87rem;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 43px;
}


.dropdown-menu {
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  width: 100%;
  background-color: #333333;
}

.dropdown > ul li a {
  color: white;
  font-family: "OpenSans-SemiBold";

}


nav {
  width: 850px;
  margin-left: 0rem;
  height: 23px;
}

nav>ul li {
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  list-style: none;
  display: inline-block;
  font-size: 0.97rem;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 2.31rem;
  font-family: "OpenSans-Bold";
  position: relative;
  /* Adicionado para o pseudo-elemento funcionar corretamente */
}

.li_header_secondary {
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  /* Adicionado para o pseudo-elemento funcionar corretamente */
}

.li_header_secondary::after {
  content: "";
  display: block;
  width: 0;
  /* Definido inicialmente como 0 */
  border-bottom: 2px solid #000000;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  position: absolute;
  bottom: -5px;
  /* Ajuste conforme necessário */
  left: 0;
  /* Ajuste conforme necessário */
}

.li_header_secondary:hover::after {
  width: 35px;
  /* Largura final desejada no hover */
}

/* END HEADER SECONDARY */

.search_item_space > img {
  position: absolute;
  right:15px;
}

.search_item_space > input {
  width: 100%;
  border: none;
  height: 20px;
  padding-left: 0.9rem;

}

.search_item_space{
  -webkit-transition: all .8s;
  -o-transition: all .8s;
  transition: all .8s;
  position:absolute;
  top:0;
  right:100%
}

.header_search {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 42px;
  margin-left: 4rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header_search>div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  width: 47px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 0.7rem;
  height: 42px;
  border: 1.1px solid #E5E5E5;
}

.container_carrousel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  width: 1320px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;

}

.container_row_card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container_vertical_banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container_vertical_banner>img {
  width: 300px;
  margin-top: 30px;
  cursor: pointer;
  height: 197px;
}

.container_card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 1320px;

  -webkit-box-orient: vertical;

  -webkit-box-direction: normal;

      -ms-flex-direction: column;

          flex-direction: column;
}

footer {
  width: 100%;
  height: 210px;
  background-color: #F6F7F7;
}

.container_card>p {
  margin-top: 2rem;
  margin-bottom: 0.9rem;
  position: relative;

  font-family: "OpenSans-Bold";
  font-size: 1.25rem;
  margin-left: 40px;
}

.text_card_description {
  width: 220px;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow-x: hidden;
  font-family: "OpenSans-Bold";
  font-size: 0.95rem;
}

.text_card_price {
  font-family: "OpenSans-Medium";
}

.container_card>p::after {
  content: "";
  position: absolute;
  display: block;

  width: 100%;
  margin-top: 0.2rem;
  border: 0.8px solid rgba(0, 0, 0, 0.477);
}

.card:hover {

  -webkit-transform: translate(0%, -5%);

      -ms-transform: translate(0%, -5%);

          transform: translate(0%, -5%);
  border: 1px solid #3333337b;
}

.card {
  cursor: pointer;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  padding: 0.9rem;
  font-family: "OpenSans-Medium";

  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.301);

          box-shadow: 0 0 5px rgba(0, 0, 0, 0.301);
  margin-top: 2rem;
  width: 294px;
  margin-left: 40px;
  height: 500px;
  border-radius: 10px;
  border: 1px solid #cccc;
}


.row_button_cart {
  position: absolute;
  bottom: 30px;
  width: 260px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 10px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.row_button_cart>button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  font-family: "OpenSans-Medium";
  font-size: 1rem;
  width: 139px;

  height: 39px;
}

.btn_remove_item_cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 120px;
  margin-bottom: 2rem;
  background-color: white;
  border: none;
}

.row_button_cart>button>svg {
  color: black;
  width: 20px;
  margin-right: 0.5rem;
}

.row_button_cart>img {
  width: 30px;
  height: 30px;
}


.description_product>.text_primary_description_product {
  font-family: "OpenSans-Medium";
  line-height: 40px;
}

.text_primary_description_product {
  border-bottom: 1px solid #333333;
}

.description_product>.text_secondary_description_product {
  font-family: "OpenSans-Regular";
  line-height: 40px;
}


.container_product {
  width: 100%;
  margin-top: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 1320px;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}



.description_product {
  max-width: 1120px;
}

.cart_icon {
  width: 40px;
  height: 40px;
}

.textPriceContainerProductPromotional {
  color: #333333;
  height: 20px;
  margin-top: 1.2rem;
  font-family: "OpenSans-SemiBold";
  font-size: 1.31rem;
}


.textPriceContainerProduct {
  color: #333333;
  height: 20px;
  font-family: "OpenSans-SemiBold";
  font-size: 1.61rem;
}

.container_product>div>.textPrimaryContainerProduct {
  color: 333333;
  height: 20px;
  font-family: "OpenSans-Medium";
  font-size: 1.31rem;
}

.container_product>div>.textSecondaryContainerProduct {
  color: #333333;
  height: 13px;
  font-family: "OpenSans-Regular";
  font-size: 0.9rem;
}



.textDescriptionContainerProduct {
  margin-top: 2rem;
  width: 550px;
  font-size: 0.9rem;
  font-family: "OpenSans-Medium";

}


.container_card_product {
  margin-top: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container_card_product>div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 2rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container_card_product>div>img {
  width: 140px;
  border-radius: 100px;
  border: 1px solid #333333;
  -o-object-fit: contain;
     object-fit: contain;
  margin-bottom: 0.9rem;
  height: 140px;
}

.container_card_product>div>p {
  width: 270px;
  font-size: 1.0rem;
  text-align: center;
  font-family: "OpenSans-Regular";

}

.container_sidebar_cart>button {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.container_plus {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 90px;
}

.container_plus>p {
  font-family: "OpenSans-Regular";
  height: 7px;
}

.container_plus>button {
  font-family: "OpenSans-Regular";
  font-size: 1.2rem;
  border-radius: 5px;
  color: white;
  background-color: black;
  border: none;
}

.button_plus {
  margin-right: 0.9rem;
}

.button_minus {
  margin-left: 0.9rem;

}

.container_card_product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.container_cards_sidebar_cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 90vh;
  overflow-y: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}



.header_sidebar_cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0rem;
  padding-right: 3rem;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header_sidebar_cart>p {
  font-size: 1.1rem;
  font-family: "OpenSans-Medium";
  height: 10px;
}



.header_sidebar_cart>img {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.container_button_sidebar_cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.container_button_sidebar_cart>button {
  -webkit-transition: all ease 0.1s;
  -o-transition: all ease 0.1s;
  transition: all ease 0.1s;
  background-color: white;
  border: 1px solid #00000090;
  font-family: "OpenSans-Regular";
  width: 90%;
  font-size: 0.9rem;
  border-radius: 6px;
  height: 40px;
  margin-bottom: 0.5rem;
}

.bottom_price_sidebar {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  bottom: 0;
  background-color: white;
  width: 100%;
  height: 140px;

}


.total_cart {
  position: absolute;
  bottom: 90px;
  margin-left: 0.9rem;
  font-size: 1.4rem;
  font-family: "OpenSans-Medium";
  height: 10px;
}

.input_frete{
  width: 220px;
}

.container_data_freight {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-family: "OpenSans-Medium";
  border-radius: 5px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 0.9rem;
  color: black;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  border: 1px solid #858585;
  margin-top: 0.9rem;
  width: 220px;
  height: 36px;
}

.container_cards_sidebar_cart {
  height: 70vh;
}

.container_sidebar_cart_close {
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;

  position: fixed;
  top: 0;
  bottom: 0;
  right: -9999px;
}

.sidebar_filter > svg{
  position: absolute;
  top:25px;
  right: 25px;
}

.sidebar_filter{

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.sidebar_filter > p {
  text-align: center;
}

.footer_sidebar_filter{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.model__options{
  margin-right: 0.4rem;
}



.sidebar_filter  .footer_sidebar_filter > a > button{
  width: 240px;
  height: 40px;
  border-radius: 5px;
  left: 50%;
  transform: translate(-50%,-50%);
  border: none;
  font-family: "OpenSans-Medium";

  color: white;
  background-color: black;
  position: absolute;
  bottom: 15px;
}

.input_range_filter{
  width: 240px;
}

.sidebar_filter > p {
  font-size: 1.2rem;
  margin-top: 1.0rem;
  margin-bottom: 1.9rem;
  font-family: "OpenSans-Medium";

}

.sidebar_filter{
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  padding-top: 1.3rem;

  z-index: 9999999999;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  position: fixed;
  top:0;
  bottom: 0;
  left: 0;
  width: 320px;
  background-color: white;
  height: 100vh;
}


.container_sidebar_cart {

  -webkit-transition: all ease .3s;

  -o-transition: all ease .3s;

  transition: all ease .3s;
  padding-top: 1.3rem;

  z-index: 99999;
  border-top-left-radius: 30px;
  border-top-left-radius: 30px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 320px;
  background-color: white;
  height: 100vh;
}

.container_cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 120px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  height: 50px;
}

.container_cart>svg {
  cursor: pointer;
}

.number_cart {
  font-family: "OpenSans-Regular";
  color: black;
  position: absolute;
  top: 0;
  right: 8px;
}


.container_product_row {
  position: relative;
  width: 500px;
}



.container_sidebar_cart>button {
  background-color: white;
  border: 1px solid #00000090;
  font-family: "OpenSans-Regular";
  width: 95%;
  border-radius: 4px;
  height: 40px;
}

.container_product_row>img {
  width: 490px;
  margin-right: 4rem;
  height: 490px;
}

.top_image_card {
  -o-object-fit: contain;
     object-fit: contain;
  width: 58px;
  height: 58px;
  position: absolute;
  right: 15px;
  top: 2px;
}

.carousel-caption {
  height: 320px;
}

.descount_card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0.9rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 80px;
  height: 40px;
  color: white;
  border-radius: 100px;
  background-color: #858585;
}

.descount_card>p {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-family: "OpenSans-Medium";
}


.center_image_card {
  height: 300px;
  object-fit: contain;
  margin-top: 2rem;
}


/* IMAGE CARROUSEL */

.container_image_carrousel {
  width: 460px;
  height: 460px;
}

/* CONTAINER ACCOUNT LOGIN */

.container_account_login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 4rem;
}

/* CONTAINER ACCOUNT */

.container_account {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 2rem;
}

.textPrimary_account {
  font-family: "OpenSans-Medium";
  font-size: 1.5rem;
}

.textSecondary_account {
  font-family: "OpenSans-Medium";
  font-size: 1.2rem;
}


.textPrimary_account::after {
  content: "";
  display: block;
  width: 100px;
  border: 1px solid #333333;
}

.textSecondary_account {
  font-family: "OpenSans-Regular";
  font-size: 1rem;
}

.textInformation_account {
  font-family: "OpenSans-Medium";
  font-size: 1.0rem;
  margin-top: 0.9rem;
}

.textCreateAccount {
  font-family: "OpenSans-Medium";
  font-size: 1.0rem;
  margin-bottom: 1.9rem;
}

/* INPUT ACCOUNT LOGIN */

.container_input_account_login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  margin-top: 1.2rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 1.2rem;
}

.container_input_account_login>label {
  font-family: "OpenSans-Regular";
  font-size: 0.9rem;
  position: relative;
  top: -40px;
  margin-bottom: 0.9rem;
}



/* INPUT ACCOUNT */

.container_input_account {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1.2rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 1rem;
}

.container_input_account>label {
  font-family: "OpenSans-Regular";
  font-size: 0.9rem;
  position: relative;
  top: -40px;
  margin-bottom: 0.9rem;
}

.row_inputs_account {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}


.input_account:focus+label,
.input_account:valid+label {
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  font-size: 0.8rem;
  color: #0c5dd7;
  position: relative;
  top: -50px
}


.input_account_login:focus+label,
.input_account_login:valid+label {
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  font-size: 0.8rem;
  color: #0c5dd7;
  position: relative;
  top: -50px
}


.container_account>button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  width: 60%;
  height: 46px;
  font-size: 0.9rem;
  border-radius: 0px;

  font-family: "OpenSans-Medium";
  color: #333333;
  background-color: white;
  border: 1px solid #333333;
  margin: 0 auto;
}


.container_account_login>.textSecondary_account {
  font-size: 1.6rem;
  margin-bottom: 1.9rem;
}

.container_account_login>button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  width: 70%;
  height: 46px;
  font-size: 0.9rem;

  border-radius: 0px;

  font-family: "OpenSans-Medium";
  color: #333333;
  background-color: white;
  border: 1px solid #333333;
  margin: 2rem auto;
}

.container_search_header{
  position: relative;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-transition: 2s;
  -o-transition: 2s;
  transition: 2s;
  padding: 0.9rem;
  width: 720px; /* Start width should be 30% to match animation start */
  font-family: "OpenSans-Regular";
  padding-left: 0.5em;
  margin-right: 1rem;
  outline: none;
  border: none;
}



.container_product_bottom_search_header{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1.2rem;

  cursor: pointer;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 0.9rem;
}


.container_product_bottom_search_header > img {
  width: 75px;
  border-radius: 100px;
  border: 1px solid #101828;
  height: 75px;
}

.container_product_bottom_search_header > div{
  margin-left: 0.9rem;
}


.container_product_bottom_search_header > p{
  white-space: nowrap;

  -o-text-overflow: ellipsis;

     text-overflow: ellipsis;
  overflow-x: hidden;
  width: 260px;
  height: 0px;
  position: relative;
  font-family: "OpenSans-Medium";
  overflow-x: hidden;
}

.container_product_bottom_search_header > div > p{
  height: 15px;
}

.bottom_search_header{
  position: absolute;
  top: 40px;
  width: 100%;
  border-radius: 5px;
  height: 190px;
  overflow-y: scroll;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.174);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.174);
  z-index: 999999;
  background-color: white;
}

.search_header_input {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-transition: 2s;
  -o-transition: 2s;
  transition: 2s;
  width: 520px; /* Start width should be 30% to match animation start */
  font-family: "OpenSans-Regular";
  padding-left: 0.5em;
  margin-right: 1rem;
  height: 35px;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(123, 123, 123, 0.626); /* Start with border */
}
.search_header_input:focus{
  border-bottom: 1px solid #0c5dd7;
}

.input_account {
  width: 320px;
  font-family: "OpenSans-Regular";
  padding-left: 0.5em;
  margin-right: 1rem;
  height: 35px;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(123, 123, 123, 0.626);
  border-radius: 0px;
}

.input_account:focus {
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  border-bottom: 1px solid #0c5dd7;
}

.input_account_login {
  width: 620px;
  font-family: "OpenSans-Regular";
  padding-left: 0.5em;
  margin-right: 1rem;
  height: 35px;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(123, 123, 123, 0.626);
  border-radius: 0px;
}

.input_account_login:focus {
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  border-bottom: 1px solid #0c5dd7;
}

.header_mobile {
  display: none;
}

.carrousel_mobile {
  display: none;
}


.image_product_row {
  width: 460px;
  height: 490px;
}


/* PAYMENT */

.container_payment_icons {
  margin-top: 1.9rem;
}


.container_payment_icons>svg {
  width: 315px;
}

.container_payment {
  position: relative;
  width: 1320px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 1rem;
  padding-left: 50px;

}

.container_primary_payment>img {
  width: 320px;
  margin-top: 1.9rem;
  margin-bottom: 1.9rem;
}

.container_primary_payment>p {
  font-family: "OpenSans-Bold";
  color: #333333;
  font-size: 1.3rem;
  margin-bottom: 0.9rem;
}

.container_primary_payment {

  margin-bottom: 1.8rem;
}

.container_payment>.container_primary_payment {
  font-family: "OpenSans-Medium";

  color: #333333;
  font-size: 1.3rem;
}

.container_primary_payment>table {

  width: 780px;
}

.container_primary_payment>table>thead>tr {
  font-family: "OpenSans-Regular";
  color: #333333;
  font-size: 1.0rem;
}

thead {
  border: 1px solid #dee2e6;
}

.image_table_payment {
  width: 55px;
  position: absolute;
  height: 55px;
}

.table>:not(caption)>*>* {
  padding: .9rem .9rem;
  border-radius: 100px;
  background-color: var(--bs-table-bg);
  border-bottom-width: 0px;
  -webkit-box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
          box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}



.update_btn_table_cart {
  width: 120px;
  height: 30px;
  font-family: "OpenSans-Medium";
  font-size: 0.9rem;
  color: #333333;
  background-color: white;
  border: 1px solid #333333;
  border-radius: 5px;
}

tbody {
  border: 1px solid #dee2e6;
}

.image_table_th {
  position: relative;
  height: 70px;
}

.container_primary_payment>table>tbody>tr th {
  font-family: "OpenSans-Regular";
  color: #333333;
  font-size: 0.9rem;
  border: 1px solid #dee2e6;
}


.container_secondary_payment>div {
  width: 90%;
}

.container_secondary_payment>div {
  margin-bottom: 0px;
}

.container_secondary_payment>div>input {
  width: 100%;
}

.container_secondary_payment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 350px;
  height: 420px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.162);
          box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.162);
  border-radius: 5px;
  border: 1px solid #dee2e6;
}

.product_name_table{
  margin-top: 15px;
  white-space: nowrap;

  -o-text-overflow: ellipsis;

     text-overflow: ellipsis;
  overflow-x: hidden;
  width: 160px;
}

.textClientInfoHeader {
  margin-top: 15px;
  white-space: nowrap;

  -o-text-overflow: ellipsis;

     text-overflow: ellipsis;
  overflow-x: hidden;
  width: 100px;
  font-size: 0.9rem;
  font-family: Poppins-Regular;
  position: relative;
  right: 12px;
}


.flex_container_secondary_payment>.text_primary_flex_secondary_payment::after {
  content: "";
  position: absolute;
  width: 90%;
  display: block;
}

.container_secondary_payment>.btn_primary_payment {
  font-family: "OpenSans-Medium";

  width: 90%;
  border: none;
  height: 40px;
  margin-top: 1.9rem;
  border-radius: 5px;
  color: white;
  background-color: #0c5dd7;
}

.text_primary_flex_secondary_payment {
  color: #858585;
  font-weight: 500;
  font-family: "OpenSans-Medium";
}

.text_secondary_flex_secondary_payment {
  color: #333333;
  font-weight: 600;

  font-family: "OpenSans-Medium";
}

.container_secondary_payment>.btn_secondary_payment {
  font-family: "OpenSans-Medium";

  width: 90%;
  border: none;
  height: 40px;
  margin-top: 0.9rem;

  border-radius: 5px;
  color: #333333;
  background-color: white;
}

.flex_container_secondary_payment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 90%;

  -webkit-box-pack: justify;

      -ms-flex-pack: justify;

          justify-content: space-between;
}

.row_input_address_payment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 720px;
}

.row_input_address_payment>div>.input_account {
  width: 380px;
}

.cupom_input {
  width: 90%;
  margin-bottom: 1.9rem;
  outline: none;
  font-size: 0.9rem;
  font-family: "OpenSans-Medium";
  border-bottom: 1px solid #858585;
  border-radius: 5px;
  height: 35px;

}


.container_profile {
  width: 720px;
  background-color: white;
  height: 618px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  padding-top: 1.2rem;
  border-radius: 15px;
  border: 1px solid #D7D7D7;
}



.container_input_modal>label {
  font-family: "OpenSans-Regular";
  font-size: 0.9rem;
  position: relative;
  top: -40px;
  margin-bottom: 0.9rem;
}

.container_input_modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}


.input_modal {
  width: 250px;
  font-family: "OpenSans-Regular";
  padding-left: 0.5em;
  margin-right: 1rem;
  height: 35px;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(123, 123, 123, 0.626);
  border-radius: 0px;
}

.input_modal:focus+label,
.input_modal:valid+label {
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
  font-size: 0.8rem;
  color: #0c5dd7;
  position: relative;
  top: -50px
}


.box_profile {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 1.9rem;

}

.row_profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.row_profile>* {
  margin-left: 1.9rem;
}

.box_profile>div {
  position: relative;
}

.box_profile>svg {
  position: absolute;
  bottom: 0;
  right: 20px;
}

.container_profile>button {
  font-family: "Poppins", Sans-serif, serif;
  color: white;
  cursor: pointer;
  margin-top: 3rem;
  font-size: 1.0rem;
  width: 320px;
  border: none;
  outline: none;
  height: 45px;
  border-radius: 35px;
  background-color: rgba(0, 0, 0, 0.815);
}


/* CARROUSEL CODE */


/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}


::-webkit-scrollbar {
  width: 8.5px;
  height: 15px;
  background: #d8d8d8;
  border-radius: 50px;
}

::-webkit-scrollbar-track {
  background: #d8d8d8;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.33);
  border-radius: 10px;
}


.sidebar {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 239px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
  background-color: white;
  border-right: 1px solid #D7D7D7;
  height: 100vh;
}

.sidebar>img {
  margin-top: 2.9rem;
  border-radius: 100px;
  margin-bottom: 2rem;
  width: 150px;
  height: 150px;
}



.checked>p {
  margin-left: 1rem;
  position: relative;
  top: -3px;
  font-size: 1.0rem;
  font-family: Poppins-Medium;
  color: white;
  height: 0px;
}

.not_checked {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  display: flex;
  margin-bottom: 1.5rem;
  width: 205px;
  height: 39px;
  padding: 1.3rem;
  padding-left: 13px;
  border-radius: 8px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}


.not_checked>img {
  width: 25px;
  position: relative;
  top: 5px;
  height: 24px;
}


.not_checked>p {
  margin-left: 1rem;
  position: relative;
  top: 1px;
  font-size: 1.0rem;
  font-family: "Poppins-Medium";
  color: #827e8f;
  height: 0px;
}

/* SIDEBAR NOT CHECKED */

.not_checked {
  cursor: pointer;

}

.checked {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  display: flex;
  background-color: rgba(0, 0, 0, 0.815);
  margin-bottom: 1.5rem;
  width: 205px;
  height: 39px;
  padding: 1.3rem;
  padding-left: 13px;
  border-radius: 8px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}


.checked>img {
  width: 25px;
  position: relative;
  top: 0px;
  height: 24px;
}

.name_product_td > img {
  width: 70px;
  border-radius: 100%;
  position: relative;
  top: 5px;
  height: 70px;
}
.checked>p {
  margin-left: 1rem;
  position: relative;
  top: -5px;
  font-size: 1.0rem;
  font-family: "Poppins-Medium";
  color: white;
  height: 0px;
}

.image_tablelist{
  width: 50px;
  height: 50px;
}


.thow_colum_layout_dashboard {
  display: -ms-grid;
  display: grid;
  background-color: #F9F9F9;
  -ms-grid-columns: 229px 1fr;
  grid-template-columns: 229px 1fr;
  height: 100%;
}

.gfg{
  margin-bottom: 0.9rem;
}

.gfg>th {
  background-color: green;
  font-family: Poppins-Regular;
  font-size: 0.97rem;

  color: #6A6A6A;
}



.gfg th,
td {
  position: relative;
  width: 180px;
  text-align: center;
  border-top: 1px solid #D7D7D7;
  font-family: Poppins-Medium;
  color: #101828;
  font-size: 0.9rem;
  border-bottom: 1px solid #D7D7D7;
  height: 70px;
  background-color: white;
}


.gfg th:first-child,
td:first-child {
  border-top-left-radius: 10px;
  border-left: 1px solid #D7D7D7;
  /* Adicionando borda à esquerda para o primeiro elemento de cada linha */
  border-bottom-left-radius: 10px;

}

.gfg th:last-child,
td:last-child {
  border-right: 1px solid #D7D7D7;
  /* Adicionando borda à esquerda para o primeiro elemento de cada linha */
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

}


.container_secondary {

  margin-top: 0rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}


.container_data {
  width: 1090px;
}

.container_data>div>button {
  font-family: "Poppins", Sans-serif, serif;
  color: white;
  cursor: pointer;
  margin-top: 0rem;
  font-size: 1.0rem;
  width: 200px;
  border: none;
  outline: none;
  height: 45px;
  margin-bottom: 0.9rem;
  border-radius: 35px;
  background-color: black;
}

.textPrimaryHeaderDashboard {
  font-family: "Poppins-SemiBold";
  font-size: 1.87rem;

  color: #373435;
}

.container_mobile {
  display: none;
}

.header_search > .search_item_space_mobile{
  display: none;
}

.bottom_input_mobile_search{
  display: none;
}

.bottom_search_header_mobile{
  display: none;
}


/* MULTI RANGE SLIDER */
.container_multi_range_slider{

  margin-left: 1.9rem;

}

.slider {
  position: relative;
  width: 260px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 10px;
}

.slider__track {
  background-color: black;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #0c5dd7;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #000000;
  font-size: 1.0rem;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 260px;
  outline: none;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: black;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  width: 22px;
  margin-top:7px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.box_freight{
  width: 100%;
}

.box_freight > p {
    margin-top: 1rem;
    width: 100%;
    font-family: "Poppins-Regular";

}

.container_card_variation > p {
  font-family: "Poppins-Regular";
  font-size: 1rem;
}

.container_card_variation{
  margin-left: 4rem;
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
  width: 420px;
}

.row_box_card_variation{
  display: flex;
  
}



.box_card_variation{
  display: flex;
  flex-direction: column;
}

.row_box_card_variation > div{
  display: flex;
  border-radius: 5px;
  justify-content: center;
  min-width: 80px;
  padding: 1.2rem;
  cursor: pointer;
  height: 30px;
  padding-top: 0.1rem;
  border: 1px solid #858585;
  margin-right: 0.9rem;
  font-size: 0.9rem;
  font-family: "Poppins-Regular";
}

.row_box_card_variation > .checked_card_variation{
  display: flex;
  border-radius: 5px;
  justify-content: center;
  min-width: 80px;
  cursor: pointer;
  height: 30px;
  padding-top: 0.1rem;
  background-color: #00000017;
  border: 1px solid #858585;
  margin-right: 0.9rem;
  font-size: 0.9rem;
  font-family: "Poppins-Regular";
  
}


.swiper {
  margin-top: 0rem;

  width: 100%;
  height: 520px;
}

.swiper-slide img {
  display: block;
  width:420px;
  height: 400px;
  object-fit: contain;
}




/* END PAYMENT */

@media screen and (max-width:1126px) {
  .container_card_variation{
    margin-left: 0rem;
    margin-top: 0.9rem;
    margin-bottom: 0.9rem;
    width: 90%;
  }
  .box_freight > p {
    margin-top: 0rem;
    width: 100%;
    font-family: "Poppins-Regular";

}

  .row_frete{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
  }
  
.box_header_image_account {
  margin-top: 2.3em;
  cursor: pointer;
  display:flex;
  position: relative;
  right: 5px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 64px;
  font-family: "Poppins-SemiBold";
  color: white;
}

.box_header_image_account > p{
  position: relative;
  top: -5px;
  font-size: 0.8rem;
  width: 120px;
}
  .container_cart {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 120px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    height: 50px;
  }

  .sidebar_filter{
    -webkit-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
    padding-top: 1.3rem;
  
    z-index: 9999999999;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    width: 320px;
    background-color: white;
    height: 100%;
  }
  .sidebar_filter  .footer_sidebar_filter > button{
    width: 240px;
    height: 40px;
    border-radius: 5px;
    border: none;
    font-family: "OpenSans-Medium";
  
    color: white;
    background-color: black;
    position: absolute;
  }

  
.container_button_sidebar_cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

  .container_sidebar_cart {

    -webkit-transition: all ease .3s;

    -o-transition: all ease .3s;

    transition: all ease .3s;
    padding-top: 1.3rem;
  
    z-index: 999999999;
    border-top-left-radius: 30px;
    border-top-left-radius: 30px;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 320px;
    background-color: white;
    height: 100%;
  }
  
  

.container_product_bottom_search_header{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1.2rem;

  cursor: pointer;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 0.9rem;
}


.container_product_bottom_search_header > img {
  width: 85px;
  position: relative;
  top: -5px;
  border-radius: 100px;
  border: 1px solid #101828;
  height: 85px;
}

.container_product_bottom_search_header > div{
  margin-left: 0.9rem;
}

.container_product_bottom_search_header > p{
  white-space: nowrap;

  -o-text-overflow: ellipsis;

     text-overflow: ellipsis;
  overflow-x: hidden;
  width: 160px;
  
  position: relative;
  font-family: "OpenSans-Medium";
  overflow-x: hidden;
}

.container_product_bottom_search_header > div > p{
  width: 220px;
  height: 3px;
  font-size: 1rem;
  height: auto;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  overflow-y: hidden;
  overflow-x: hidden;

}

  .bottom_search_header{
    display: none;
  }

  .header_search > .search_item_space{
    display: none;
  }
  .header_search > .search_item_space_mobile{
    display: block;
    background: white;
  }

  .header_search > .search_item_space_mobile > img {
    position: absolute;
    right:15px;
  }
  
  .header_search > .search_item_space_mobile > input {
    width: 100%;
    border: none;
    height: 30px;
    padding-left: 0.9rem;
  
  }
  .container_search_header{
    position: relative;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-transition: 2s;
    -o-transition: 2s;
    transition: 2s;
    padding: 0.9rem;
    width: 100%; /* Start width should be 30% to match animation start */
    font-family: "OpenSans-Regular";
    padding-left: 0.5em;
    margin-right: 0rem;
    outline: none;
    border: none;
  }
  
  .bottom_search_header_mobile{
    display: block;
    position: absolute;
    top: 40px;
    width: 100%;
    left: 50%;
    right: 50%;
    -webkit-transform: translate(-50%,0%);
        -ms-transform: translate(-50%,0%);
            transform: translate(-50%,0%);
    border-radius: 5px;
    height: 190px;
    overflow-y: scroll;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.174);
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.174);
    z-index: 999999;
    background-color: white;
  }
  

  .bottom_input_mobile_search{
    display: block;
    min-height: 120px;
    position: absolute;
    width: 100%;
    left: 0;
  }

  .header_search > .search_item_space_mobile{
    -webkit-transition: all .8s;
    -o-transition: all .8s;
    transition: all .8s;
    width: 100%;
    position:absolute;
    top:0;
    right:100%
  }
  
  .box_profile_mobile{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.box_profile_mobile > div{
    position: relative;
    top: -15px;

}


  .sidebar {
    display: none;
  }

  .company_icon_sidebar_mobile{
    width: 120px;
    height: 120px;

  }

  .header_dashboard_mobile{
    height: 120px;
    padding-bottom: 0.9rem;
  }

  .container_profile_mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;

    -webkit-box-orient: vertical;

    -webkit-box-direction: normal;

        -ms-flex-direction: column;

            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .container_profile_mobile>button {
    font-family: "Poppins", Sans-serif, serif;
    color: white;
    cursor: pointer;
    margin-top: 3rem;
    font-size: 1.0rem;
    width: 79%;
    border: none;
    outline: none;
    height: 45px;
    margin-bottom: 2rem;
    border-radius: 35px;
    background-color: rgba(0, 0, 0, 0.128);
  }

  .container_mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    background-color: white;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
  }

  .thow_colum_layout_dashboard {
    display: -ms-grid;
    display: grid;
    background-color: #F9F9F9;
    -ms-grid-columns: 0 1fr;
    grid-template-columns: 0 1fr;
    height: 100%;
  }



  .row_button_cart {
    bottom: 30px;
    width: 250px;
  }

  .control_carrousel {
    position: relative;
    height: 500px;

    width: 100%;
  }

  .container_card_carrousel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 90%;
    margin: 0 auto;
    scroll-behavior: smooth;
    overflow-x: scroll;
  }





  .container_animation_pages {
    position: fixed;
    top: 0;
    background: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 100vh;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
            z-index: 99999999999;
  }

  .container_animation_pages>div {
    width: 80%;
  }

  .container_data {
    display: none;
  }

  .container_payment {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 4rem;
    padding-left: 0px;
  }

  .container_primary_payment {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }


  .row_input_address_payment {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
  }




  .container_secondary_payment {

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 70%;
    margin: 0 auto;
    height: 420px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.162);
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.162);
    border-radius: 5px;
    border: 1px solid #dee2e6;
  }


  .container_select {
    width: 220px;
    margin-top: 1.9rem;
  }

  .container_product_row {
    width: 100%;
  }

  .image_product_row {
    margin: 0 auto;
    width: 89%;
    height: 80%;
  }

  .container_carrousel {
    display: none;
  }

  .container_row_filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
  }

  .container_product>div>.textPrimaryContainerProduct {
    color: 333333;
    height: 70px;
    width: 90%;
    font-family: "OpenSans-Medium";
    font-size: 1.3rem;

    text-align: center;
  }

  .container_product>div>.textSecondaryContainerProduct {
    color: #333333;
    height: 23px;
    width: 90%;
    text-align: center;
    font-family: "OpenSans-Regular";
    font-size: 1rem;
  }

  .custom-select {
    position:relative;
    padding-left: 0.1rem;
    width: 100%;
    border-radius: 5px;
    font-family: "Poppins-Medium";
    font-size: 0.9rem;
    padding:0.6rem;
    border-radius: 5px;
    outline: none;
    border: 1px solid #858585;
  
  }
  .container_select_variation{
    display: -webkit-box;
    display: -ms-flexbox;
    width: 100%;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 100px;
    margin-bottom: 0rem;
    margin-left: 0rem;
  }
  .btn_sale {
    display: none;
    width: 90%;
    padding: 1rem;
    margin-top: 0rem;
    margin-bottom:3rem;
    height: 43px;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    font-family: "OpenSans-Medium";
    font-size: 0.9rem;
    color:white;
    background-color: #0c5dd7;
    border-radius: 5px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .description_product>.text_primary_description_product {
    width: 100%;
    font-family: "OpenSans-Medium";
    line-height: 40px;
  }

  .text_primary_description_product {
    border-bottom: 1px solid #333333;
  }


  .input_frete {
    outline: none;
    width: 100%;
    font-size: 0.9rem;
    font-family: "OpenSans-Medium";
    border: 1px solid #858585;
    border-radius: 4px;
    min-height: 45px;
    margin-bottom: 0.9rem;
  }
  .box_freight{
    width: 100%;
    
  }
  .container_data_freight {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    font-family: "OpenSans-Medium";
    border-radius: 5px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-top: 0.9rem;
    color: black;
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    border: 1px solid #858585;
    margin-top: 0rem;
    margin-bottom: 3rem;
    width: 100%;
    height: 45px;
  }
  .container_frete>img {
    width: 50px;
    height: 50px;
  }

  .container_frete>p {
    font-family: "OpenSans-Medium";
    margin-top: 0.1rem;
    font-size: 0.9rem;
  }

  .container_frete {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    margin-top: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 90%;
    border-top: 1px solid black;
  }



  .textDescriptionContainerProduct {
    margin-top: 2rem;
    width: 90%;
    text-align: center;
    font-size: 0.9rem;
    font-family: "OpenSans-Medium";
  }

  .description_product {
    width: 90%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .container_texts {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .container_texts>p {

    text-align: center;
  }

  .container_product {
    width: 100%;
    margin-top: 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    -webkit-box-pack: justify;

        -ms-flex-pack: justify;

            justify-content: space-between;

  }



  .row_inputs_account {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }


  .container_account {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 1.0rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    flex-direction: column;
    margin-top: 2rem;
  }

  .container_input_account_login > input{
    width: 100%;
  }

  .container_account_login > button{
    width: 100%;
  }
  
  .container_account_login {
    width: 90%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    flex-direction: column;
    margin-top: 2rem;
  }


  .container_card>p::after {
    content: "";
    margin: 0 auto;
    border: none;
  }

  .container_card>p {
    margin-top: 2rem;
    position: relative;
    text-align: center;
    font-family: "OpenSans-Bold";
    font-size: 1.25rem;
    margin-left: 0rem;
  }


  .carrousel_mobile {
    display: block;
  }

  .container_header_secondary>.dropdown>button {
    background-color: #333333;
    width: 220px;
    border: none;
    font-family: "OpenSans-SemiBold";
    color: white;
    padding: 0.6rem;
    display: none;
    font-size: 0.87rem;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 43px;
  }

  .container_carrousel {
    display: none;
    margin-top: 0rem;
    width: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;

  }

  .header {
    display: none;
  }


  .container_cart_mobile {
    position: relative;
    top: 40;
  }

  .container_cart_mobile>p {

    font-family: "OpenSans-Regular";
    color: black;
    position: absolute;
    top: 0;
    right: 7px;

  }

  .header_mobile {
    width: 100%;
    padding-right: 0.9rem;
    padding-left: 0.9rem;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    z-index: 99999;
    background-color: #0c5dd8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .header_mobile>img {
    width: 90px;
    height: 90px;
    border-radius: 100px;
    -o-object-fit: contain;
       object-fit: contain;
  }



  .myCarousel {
    display: none;
  }

  nav {
    display: none;
  }

  .container_card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 5rem;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  footer {
    display: block;
  }

  .container_row_card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  #myCarousel {
    display: none;
    width: 100%;
    padding: 0rem;
    margin-top: 0rem;
    margin-left: 0rem;
  }

  .carousel-item>img {
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    height: 420px;
  }

  .container_carrousel {
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .box_header_secondary {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 42px;
    padding-left: 0rem;
    padding-right: 0rem;
    border-bottom: 1px solid #E5E5E5;
  }

  .container_items_mobile_carrousel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }

  .flex_buttons_carrousel {
    position: absolute;
    top: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: 0px;
    width: 100%;
    z-index: 999999;
  }

  .flex_buttons_carrousel>button {
    background: none;
    margin-left: 0px;
    border: none;
  }

  .card {
    position: relative;
    cursor: pointer;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    padding: 0.9rem;
    font-family: "OpenSans-Medium";
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.301);
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.301);
    margin-top: 3rem;
    width: 280px;
    margin-left: 22px;
    height: 500px;
    border-radius: 10px;
    border: 1px solid #cccc;
  }



  .center_image_card {
    margin-top: 2rem;
    width: 100%;
  }

  .container_primary_payment_mobile{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }


  .header_search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    height: 42px;
    margin-left: 0rem;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .clean_mobile_payment{
    height: 3rem;
  }

  .carousel-caption>p {
    display: none;
  }

  .carousel-caption {
    height: 190px;
  }

  .container_secondary_payment {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 90%;
    height: 420px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.162);
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.162);
    border-radius: 5px;
    border: 1px solid #dee2e6;
  }

  .container_primary_payment {
    width: 100%;
  }


  .container_primary_payment>table {
    display: none;
  }

  .row_input_address_payment {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }

  .row_input_address_payment>div {
    width: 90%;
  }

  .row_input_address_payment>div>.input_account {
    width: 100%;
  }

  .table_mobile {
    margin-top: 2rem;
    width: 90%;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .body_table_mobile{
    width: 90%;
  }

  .table_mobile>.body_table_mobile>p {
    font-family: "OpenSans-Medium";
    font-size: 1rem;
    margin-left: 0.9rem;
  }

  .body_table_mobile>button {
    margin-left: 0.9rem;

    background-color: #333333;
    width: 80%;
    border: none;
    font-family: "OpenSans-SemiBold";
    color: white;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    border-radius: 5px;
    font-size: 0.87rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 33px;
  }


  .header_table_mobile>img {
    width: 120px;
    height: 120px;
    border-radius: 0px;
  }

}